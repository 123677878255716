<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="main-body">
        <!-- <div class="select-one">
            <Select v-model="devSearchList" clearable style="width:200px">
                <Option v-for="item in devSearchLists" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <Input prefix="ios-search"  v-model="searchText" placeholder="请输入搜索内容" style="width: auto" />
        </div> -->
        <div class="select-two main-search">
          <Select
            v-model="searchType"
            style="width:110px"
            @on-change="resetGetInfo"
          >
            <Option
              v-for="item in devSearchLists"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
          <Input
            prefix="ios-search"
            v-model="searchText"
            placeholder="请输入搜索内容"
            style="width: 280px"
            @on-enter="resetGetInfo"
          />

          <div class="st-block ml10">
            <div class="st-label">故障类型</div>
            <div class="st-select">
              <Select v-model="breakdownType" @on-change="resetGetInfo">
                <!-- <Option v-for="item in devSearchLists" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
                <!-- <Option value="">全部</Option> -->
                <Option value="-1">全部</Option>
                <Option value="1">机械故障</Option>
                <Option value="2">电气故障</Option>
                <Option value="3">盘头缺失</Option>
                <Option value="4">纬纱缺失</Option>
              </Select>
            </div>
          </div>
          <div class="st-block ml10">
            <div class="st-label">维修结束时间</div>
            <div class="st-datepicker">
              <DatePicker
                v-model="workData"
                format="yyyy-MM-dd"
                type="daterange"
                placement="bottom-end"
                placeholder="请选择维修结束时间"
                @on-change="resetGetInfo"
              ></DatePicker>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <div class="btn-block" @click="resetGetInfo">
            <i class="icon1 iconshuaxin"></i>
            &nbsp;&nbsp;刷新
          </div>
          <div class="btn-block" @click="exportData">
            <i class="icon1 icondaochu"></i>
            &nbsp;&nbsp;导出数据
          </div>
        </div>
        <div class="table-area">
          <Table
            class="devTable"
            max-height="750"
            border
            no-data-text=" "
            ref="selection"
            :loading="loading"
            :columns="theadDate"
            :data="tableDate"
          ></Table>
          <!-- <Button @click="handleSelectAll(true)">Set all selected</Button>
            <Button @click="handleSelectAll(false)">Cancel all selected</Button> -->
        </div>

        <div class="main-page">
          <Page
            :total="total"
            show-sizer
            show-elevator
            show-total
            :page-size="pageSize"
            :current="pageNum"
            @on-page-size-change="pagesizechange"
            @on-change="pagenumberchange"
          />
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: "",
      workData: [],
      // 顶部筛选
      devSearchLists: [
        {
          value: "1",
          label: "检修单号"
        },
        {
          value: "2",
          // label: '设备编号'
          label: "机台号"
        },
        {
          value: "3",
          label: "设备型号"
        },
        {
          value: "4",
          label: "生产厂家"
        }
      ],
      searchType: "2", // 顶部第一个select选择的东西
      searchText: "", // 顶部搜索内容
      breakdownType: "-1", // 非必填，1开始维修，2结束维修
      startTime: "",
      endTime: "",
      pageSize: 10,
      pageNum: 1,
      total: 0,
      pages: 0,
      theadDate: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index + (this.pageNum - 1) * this.pageSize + 1
            );
          },
          align: "center",
          width: 48
        },
        {
          title: "操作",
          key: "breakdownId",
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            const arr = [];
            arr.push(
              h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  style: {
                    marginRight: "5px",
                    boxShadow: "none",
                    color: "#2980B9"
                  },
                  on: {
                    click: () => {
                      // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                      this.$router.push({
                        path: "/deviceManage/recordDetail",
                        query: {
                          breakdownId: params.row.breakdownId,
                          dealerName: params.row.dealerName
                        }
                      });
                    }
                  }
                },
                "查看"
              )
            );
            return h("div", arr);
          }
        },
        {
          title: "检修单号",
          key: "breakdownCode",
          className: "blueFont",
          minWidth: 125,
          render: (h, params) => {
            const arr = [];
            arr.push(
              h(
                "div",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  style: {
                    marginRight: "5px",
                    boxShadow: "none",
                    color: "#515A6E"
                  },
                  on: {
                    click: () => {
                      // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                    }
                  }
                },
                params.row.breakdownCode
              )
            );

            return h("div", arr);
          }
        },
        {
          // title: '设备编号',
          title: "机台号",
          key: "deviceNumber",
          minWidth: 60
        },
        {
          // title: '设备编号',
          title: "车间名称",
          key: "workshopName",
          minWidth: 125
        },
        {
          title: "故障类型",
          key: "breakdownType",
          minWidth: 80,
          render: (h, params) => {
            const arr = [];
            if (params.row.breakdownType === 1) {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      boxShadow: "none"
                      // color: '#2980B9'
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                      }
                    }
                  },
                  "机械故障"
                )
              );
            } else if (params.row.breakdownType === 2) {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      boxShadow: "none"
                      // color: '#2980B9'
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                      }
                    }
                  },
                  "电气故障"
                )
              );
            } else if (params.row.breakdownType === 3) {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      boxShadow: "none"
                      // color: '#2980B9'
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                      }
                    }
                  },
                  "盘头缺失"
                )
              );
            } else if (params.row.breakdownType === 4) {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      boxShadow: "none"
                      // color: '#2980B9'
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                      }
                    }
                  },
                  "纬纱缺失"
                )
              );
            }

            return h("div", arr);
          }
        },
        {
          title: "上报人",
          key: "pusherName",
          minWidth: 95
        },
        {
          title: "故障上报时间",
          key: "datePush",
          minWidth: 150
        },
        {
          title: "维修开始时间",
          key: "dealDateStart",
          minWidth: 150
        },
        {
          title: "维修结束时间",
          key: "dateDealEnd",
          minWidth: 150
        },
        {
          title: "故障持续时间(分钟)",
          key: "continueTime",
          minWidth: 140
        },
        {
          title: "检修人",
          key: "dealerName",
          minWidth: 95
        },
        {
          title: "处理记录",
          key: "remark",
          minWidth: 150
        }
      ],
      tableDate: [
        // {
        //   devstate:'2',
        //   state:2
        // }
      ],
      loading: false
    };
  },
  methods: {
    resetGetInfo() {
      this.pageSize = 10;
      this.pageNum = 1;
      this.searchList();
    },
    // 修改每页条数
    pagesizechange(pagesize) {
      this.pageSize = pagesize;
      this.pageNum = 1;
      this.searchList();
    },
    // 修改页码
    pagenumberchange(pagenum) {
      this.pageNum = pagenum;
      this.searchList();
    },
    // 导出数据
    exportData() {
      const that = this;
      window.location.href =
        that.$api.baseUrl +
        "/iext/back/device/DeviceBreakdownController/exportBreakdownList";
      // useaxios
      // .axios(
      //     that.$api.baseUrl + '/iext/back/device/DeviceBreakdownController/exportBreakdownList',
      //     {

      //     },
      //     'get'
      //   )
      //   .then(res => {
      //     if (res.data.success === 1) {
      //       console.log("导出成功")
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    // 当查询条件变化时调用
    searchList() {
      this.loading = true;
      const that = this;
      var startTime = "";
      var endTime = "";
      if (this.workData[0] !== "") {
        var d = this.workData[0];
        var d1 = this.workData[1];
        startTime =
          d.getFullYear() +
          "-" +
          this.p(d.getMonth() + 1) +
          "-" +
          this.p(d.getDate());
        endTime =
          d1.getFullYear() +
          "-" +
          this.p(d1.getMonth() + 1) +
          "-" +
          this.p(d1.getDate());
      }
      this.axios({
        url: "/iext/back/device/DeviceBreakdownController/breakdownList",
        method: "get",
        params: {
          pageSize: that.pageSize,
          pageNum: that.pageNum,
          searchType: that.searchType,
          searchText: that.searchText,
          breakdownType: that.breakdownType === "-1" ? "" : that.breakdownType,
          startTime: startTime,
          endTime: endTime
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.loading = false;
            that.tableDate = res.data.body.list.result;
            that.total = res.data.body.list.total;
            that.pages = res.data.body.list.pages;
            that.pageSize = res.data.body.list.pageSize;
            that.pageNum = res.data.body.list.pageNum;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // p为不够10添加0的函数
    p(s) {
      return s < 10 ? "0" + s : s;
    }
  },
  created() {},
  mounted() {
    this.searchList();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .select-one {
    margin: 16px 0;
  }
  .select-two {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .st-block {
      height: $newIpt-height;
      display: flex;
      .st-label {
        line-height: $newIpt-height;
        margin-right: 10px;
      }
      .st-select {
        width: 108px;
        height: $newIpt-height;
      }
      .st-datepicker {
        width: 240px;
        height: $newIpt-height;
      }
    }
    .ml10 {
      margin-left: 10px;
    }
  }
  .btn-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .btn-block {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
    .btn-block:hover {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: #2980b9;
    }
  }
  .table-area {
    .devTable {
      table {
        // position: fixed !important;
      }
    }
    .ivu-table-wrapper >>> .ivu-table {
      .ivu-table-body {
        // overflow-y: scroll;
        //     max-height: 560px;
        //     overflow-x: inherit;
        // position: fixed !important;
        // tbody .ivu-table-row {
        //   .blueFont{
        //     color:rgba(87,163,243,1);
        //   }
        // }
        tbody .ivu-table-row:hover {
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
            background: rgba(235, 247, 255, 1);
          }
          td .ivu-table-cell .ivu-btn {
            border: none;
            box-shadow: none;
            background: rgba(235, 247, 255, 1);
          }
        }

        tbody .ivu-table-row {
          background: black;
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
            .ivu-select-selected-value {
              padding-left: 0;
            }
          }
          td .ivu-table-cell div {
            border: none;
            box-shadow: none;
            padding: 0px;
          }
          .blueFont {
            color: rgba(87, 163, 243, 1);
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.mainbody {
  .ivu-tabs-nav-container {
    /*background:rgba(228,235,241,1)*/
  }
  .el-tree-node__content {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid rgba(223, 227, 232, 1);
  }
}
</style>
